import { Link } from "react-router-dom";
import { AboutStyled } from "./about.styled";

export const About = () => {
  return (
    <AboutStyled id="aboutId">
      <div className="about-inner">
        <div className="container">
          <div className="section-titles-wrp">
            <h2 className="section-title">About Me</h2>
          </div>

          <div className="about-content">
            <div className="image-col">
              <div className="image-col-inner" />
            </div>

            <div className="content-col">
              <p>
                Hi, I’m Senal 👋, a software developer originally from Sri Lanka
                🇱🇰, now based in Perth, Australia. I have a passion for building
                scalable applications, problem-solving, and exploring new
                technologies.
              </p>

              <p>
                I completed my Bachelor’s in Software Engineering from{" "}
                <a
                  href="https://www.nsbm.ac.lk/"
                  rel="noreferrer"
                  target="_blank"
                >
                  NSBM Green University
                </a>{" "}
                in partnership with the{" "}
                <a
                  href="https://www.plymouth.ac.uk/"
                  rel="noreferrer"
                  target="_blank"
                >
                  University of Plymouth
                </a>{" "}
                in 2018. After graduating, I worked at{" "}
                <a
                  href="https://smashydesign.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Smashy Design
                </a>{" "}
                as a Software Developer until October 2021, focusing on
                front-end development, UI/UX, and system architecture.
              </p>

              <p>
                In November 2021, I joined{" "}
                <a href="https://embla.asia/" rel="noreferrer" target="_blank">
                  Embla Software Innovation
                </a>{" "}
                as a Full Stack Software Developer, where I worked on building
                web applications, API integrations, and scalable cloud-based
                solutions until November 2022.
              </p>

              <p>
                Looking to expand my knowledge and skillset, I moved to Perth in
                December 2022 to pursue my Master of Computer Science at Edith
                Cowan University. During this time, I’ve gained experience in
                cloud computing, AI, and data-driven applications, and I’m
                always eager to explore new technologies and innovative
                solutions.
              </p>

              <p>
                Outside of work, you’ll find me exploring new places, enjoying a
                good cup of coffee ☕, and playing basketball 🏀 and cricket 🏏.
              </p>

              <p>Let’s connect and build something amazing!</p>

              <div className="btn-row">
                <a
                  href="senal-dolage-resume.pdf"
                  target="_blank"
                  className="btn btn-light"
                >
                  Résumé
                </a>
                <Link to="/projects" className="btn btn-light">
                  Projects
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyled>
  );
};
