import { FooterStyled } from './footer.styled';
import { FaFacebook, FaGithub, FaLinkedin, FaMailBulk, FaPhone } from 'react-icons/fa';

export const Footer = () => {
    return (
        <FooterStyled className="footer">
            <div className="footer-inner">
                <div className="container">
                    <div className="icons-wrp">
                        <ul>
                            <li>
                                <a href="https://github.com/SenalDolage" target="_blank" rel="noreferrer">
                                    <FaGithub />
                                </a>
                            </li>

                            <li>
                                <a href="https://www.linkedin.com/in/senaldolage/" target="_blank" rel="noreferrer">
                                    <FaLinkedin />
                                </a>
                            </li>

                            <li>
                                <a href="mailto:senal.dolage@gmail.com" rel="noreferrer">
                                    <FaMailBulk />
                                </a>
                            </li>

                            <li>
                                <a href="tel:+61433426223" rel="noreferrer">
                                    <FaPhone />
                                </a>
                            </li>

                            <li>
                                <a href="https://www.facebook.com/senaldolage" target="_blank" rel="noreferrer">
                                    <FaFacebook />
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="copyright-wrp">
                        <p>© {new Date().getFullYear()}</p>
                    </div>
                </div>
            </div>
        </FooterStyled>
    );
}
